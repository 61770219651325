<template>
  <div class="vue-facture-obligee">
    <b-button
      size="sm"
      class="button-export-style ml-2 pl-2 pr-2"
      title="Vue Facture"
      @click="handleClickVueFacture"
      v-if="getAppeliPaiementThTableObligee.length"
    >
      <font-awesome-icon icon="eye" />
    </b-button>
    <b-modal
      ref="vue-facture-oblige-modal"
      title="Reglement Factures obligés"
      size="lg"
      no-close-on-backdrop
      :hide-footer="true"
      modal-class="modalLcrClass"
    >
      <Card>
        <template v-slot:body>
          <div class="box-all-filter">
            <div class="box-end-position">
              <reglement
                class="mr-1"
                v-if="checkPermission('APTHAR') && computedCheckedRowsOneClient"
                :dataToUse="computedCheckedRowsOneClientFacture"
                :societeRequired="computedUserData"
                interfaceModal="ReglementFactureRowOblige"
                interfaceName="oblige"
                @updateDataInParentComponentReglement="
                  updateDataInParentComponentReglement
                "
              />
            </div>
          </div>

          <div v-if="loaderData == true" class="loading-custom-template-block">
            <Half-circle-spinner
              :animation-duration="1000"
              :size="50"
              :color="'#4d4bac'"
              class="loeder"
            />
          </div>
          <b-table
            show-empty
            id="my-table"
            class="custom-table-style tableFactureObligeeVue table-header"
            :items="dataOblige"
            :fields="computedDFields"
            bordered
            sticky-header
            hover
            responsive
            head-variant="light"
            :tbody-tr-class="rowClass"
            empty-text="Il n'y a aucun enregistrement à afficher"
          >
            <template v-slot:head()="data">
              <div>
                <span v-if="data.field.key != 'check_all'">
                  {{ data.field.label }}</span
                >
                <div v-else>
                  <b-form-checkbox
                    v-model="checkAll"
                    @change="checkAllFunction"
                    class="check-th"
                    :value="true"
                    :unchecked-value="false"
                  >
                  </b-form-checkbox>
                </div>
              </div>
            </template>
            <template v-slot:cell(check_all)="data">
              <b-form-checkbox
                v-model="data.item.check"
                class="check-th"
                :value="true"
                :unchecked-value="false"
              >
              </b-form-checkbox>
            </template>
            <template v-slot:cell(vendeur)="data">{{
              data.item.vendeur
            }}</template>
            <template v-slot:cell(lot)="data">{{ data.item.lot }}</template>
            <template v-slot:cell(client)="data">{{
              data.item.client
            }}</template>

            <template v-slot:cell(numero_facture)="data">{{
              data.item.num
            }}</template>
            <template v-slot:cell(ht)="data">{{ data.item.total_ht }}</template>
            <template v-slot:cell(tva)="data">{{
              data.item.total_tva
            }}</template>
            <template v-slot:cell(ttc)="data">{{
              data.item.total_ttc
            }}</template>
            <template v-slot:cell(reste)="data"
              >{{ data.item.reste }} €</template
            >
            <template v-slot:cell(etat)="data">{{ data.item.etat }}</template>
            <template v-slot:cell(statut_client)="data">{{
              data.item.statut_client
            }}</template>

            <template v-slot:cell(date_paiement)="data">{{
              data.item.date_paiement
            }}</template>
            <template v-slot:cell(reglement)="data"
              >{{ data.item.montant_regle }} €
              <font-awesome-icon
                v-show="data.item.reglements.length"
                :id="`popover-1-${data.index}`"
                icon="info"
                class="info-icon-commercial-lot content-tooltip-style w-25"
              />

              <b-popover
                :target="`popover-1-${data.index}`"
                triggers="hover"
                custom-class="avoir-tooltip"
              >
                <b-table-simple class="table-fiche">
                  <b-tbody>
                    <b-tr class="title-ligne">
                      <b-th class="newcolor">Montant reglé</b-th>
                      <b-th class="newcolor">Date de paiement</b-th>
                      <b-th class="newcolor">Type de paiement</b-th>
                    </b-tr>
                    <template v-for="(reglement, i) in data.item.reglements">
                      <b-tr :key="'reglement' + i">
                        <b-td class="newcolor">{{ reglement.amount }} €</b-td>
                        <b-td class="newcolor">{{
                          formateDateGetters(reglement.payment_date)
                        }}</b-td>
                        <b-td class="newcolor">{{
                          reglement.payment_type
                        }}</b-td>
                      </b-tr>
                    </template>
                  </b-tbody>
                </b-table-simple>
              </b-popover>
            </template>
          </b-table>
          <div
            v-if="
              computedTotal &&
                (computedTotal.ht > 0 ||
                  computedTotal.ttc > 0 ||
                  computedTotal.someApaye > 0)
            "
            class="style-somme-th-ttc"
          >
            <p class="m-2">
              Total ht:
              {{   computedTotal.ht
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                .replaceAll(',', ' '), }}
              € {{ '   ' }}Total ttc:

              {{
                computedTotal.ttc
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  .replaceAll(',', ' ')
              }}
              € Total à payer :
              {{
                computedTotal.someApaye
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  .replaceAll(',', ' ')
              }}
              €
            </p>
          </div>
        </template></Card
      ></b-modal
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import NewLModelRowOblige from '@/models/NewLModelRowOblige';
export default {
  data() {
    return {
      checkAll: false,
      dataOblige: [],
      loaderData: false
    };
  },
  computed: {
    ...mapGetters([
      'getAppeliPaiementThTableObligee',
      'formateDateGetters',
      'checkPermission',
      'getUserData'
    ]),
    computedDFields() {
      let fields = [
        {
          key: 'check_all',
          label: '',
          thClass: 'th-check-obligee',
          tdClass: 'col-check-obligee',
          show: true
        },
        {
          key: 'lot',
          label: 'Lot',
          show: true,
          thClass: 'th_stiky_num_simulation',
          tdClass: 'col_stiky_num_simulation'
        },
        {
          key: 'client',
          label: 'Client',
          show: true
        },
        {
          key: 'vendeur',
          label: 'Vendeur',
          show: true
        },
        {
          key: 'numero_facture',
          label: 'Numéro de facture',
          show: true
        },
        {
          key: 'ht',
          label: 'Montant HT',
          show: true
        },
        { key: 'tva', label: 'Tva', show: true },
        {
          key: 'ttc',
          label: 'Montant TTC',
          show: true,
          thClass: 'montant-width-class',
          tdClass: 'montant-width-class'
        },
        {
          key: 'reste',
          label: 'Reste à payer',
          show: true,
          thClass: 'montant-width-class',
          tdClass: 'montant-width-class'
        },
        {
          key: 'etat',
          label: 'Statut de facture',
          show: true
        },
        {
          key: 'statut_client',
          label: 'Statut client',
          show: true
        },
        {
          key: 'date_paiement',
          label: 'Date de paiement',
          show: true
        },
        {
          key: 'reglement',
          label: 'Reglement',
          show: true
        }
      ];
      return fields.filter(i => i.show == true);
    },
    computedUserData() {
      return this.getUserData?.filiales.length == 0 ? true : false;
    },
    computedCheckedRowsOneClient() {
      return this.computedCheckedRowsOneClientFacture.length ==
        this.dataOblige.filter(i => i.check == true).length &&
        this.computedCheckedRowsOneClientFacture.length > 0
        ? true
        : false;
    },
    computedCheckedRowsOneClientFacture() {
      let tableReglement = [];

      this.dataOblige.forEach(facture => {
        if (
          facture.check == true &&
          facture.reste != '0' &&
          facture.type != 'Facture proforma' &&
          (facture.etat == 'A payer' || facture.etat == 'Payé en partie')
        ) {
          if (tableReglement.length > 0) {
            if (
              facture.client_id == tableReglement[0].client_id &&
              facture.vendeur_id == tableReglement[0].vendeur_id &&
              facture.typeReglement == tableReglement[0].typeReglement
            ) {
              tableReglement.push(facture);
            }
          } else {
            tableReglement.push(facture);
          }
        }
      });

      return tableReglement;
    },
    computedTotal() {
      let someHt = 0;
      let someTtc = 0;
      let someApaye = 0;

      for (let i = 0; i < this.dataOblige.length; i++) {
        if (this.dataOblige[i].check == true) {
          someHt =
            someHt +
              Math.round(
                parseFloat(
                  this.dataOblige[i].total_ht +
                    ''.replace(' ', '').replace(',', '.')
                ) * 100
              ) /
                100 || 0;
          someTtc =
            someTtc +
              Math.round(
                parseFloat(
                  this.dataOblige[i].total_ttc +
                    ''.replace(' ', '').replace(',', '.')
                ) * 100
              ) /
                100 || 0;
          if (
            this.dataOblige[i].type == 'Facture' &&
            (this.dataOblige[i].etat == 'A payer' ||
              this.dataOblige[i].etat == 'Payé en partie' ||
              this.dataOblige[i].etat == 'Payé')
          ) {
            someApaye =
              someApaye +
                Math.round(
                  parseFloat(
                    this.dataOblige[i].reste +
                      ''.replace(' ', '').replace(',', '.')
                  ) * 100
                ) /
                  100 || 0;
          }
        }
      }
      return {
        ht: Math.round(someHt * 100) / 100,
        ttc: Math.round(someTtc * 100) / 100,
        someApaye: Math.round(someApaye * 100) / 100
      };
    }
  },
  methods: {
    rowClass(item, type) {
      if (item && item.reste == 0) {
        return 'ligneAlert';
      }
      return 'ligneNormale';
    },
    updateDataInParentComponentReglement(payload) {
      payload.data.forEach(element => {
        payload.response.factures_regles.forEach(fact => {
          var index = element.lot_origin.missing_prime_factures
            .map(e => e.id)
            .indexOf(fact.id);
          var type_fact = 'missing_prime_factures';
          if (index < 0) {
            index = element.lot_origin.prime_factures
              .map(e => e.id)
              .indexOf(fact.id);
            type_fact = 'prime_factures';
          }
          if (index < 0) {
            index = element.lot_origin.surprime_factures
              .map(e => e.id)
              .indexOf(fact.id);
            type_fact = 'surprime_factures';
          }
          if (index < 0) {
            index = element.lot_origin.commission_factures
              .map(e => e.id)
              .indexOf(fact.id);
            type_fact = 'commission_factures';
          }
          if (index < 0) {
            index = element.lot_origin.commission_gestion_factures
              .map(e => e.id)
              .indexOf(fact.id);
            type_fact = 'commission_gestion_factures';
          }
          if (index >= 0) {
            element.lot_origin[type_fact][index].reste = fact.reste;
            element.lot_origin[type_fact][index].reglements = fact.reglements;
            element.lot_origin[type_fact][index].date_echeance =
              fact.date_echeance;
            element.lot_origin[type_fact][index].montant_regle =
              fact.montant_regle;
            element.lot_origin[type_fact][index].date_paiement =
              fact.date_paiement;
            element.lot_origin[type_fact][index].payment_type =
              fact.payment_type;
            element.lot_origin[type_fact][index].etat = fact.etat;
            element.lot_origin[type_fact][index].statut_client =
              fact.statut_client;
          }
        });
      });
    },
    checkAllFunction() {
      this.dataOblige.map(item => {
        item.check = this.checkAll;
      });
    },
    handleClickVueFacture() {
      this.loaderData = true;
      this.dataOblige = [];
      this.$refs['vue-facture-oblige-modal'].show();
      this.getAppeliPaiementThTableObligee.forEach(filiale => {
        filiale.lots.forEach(lot => {
          //missing prime facture
          if (this.checkPermission('GAPTHACI')) {
            let mission_prime_fact = lot.missing_prime_factures.filter(
              i => i.etat != 'Annulée' && i.belongsTo == true
            );
            mission_prime_fact.forEach(element => {
              this.dataOblige.push(
                NewLModelRowOblige.create({
                  ...element,
                  lot: lot.name,
                  lot_origin: lot
                })
              );
            });
          }
          //prime facture
          if (this.checkPermission('GAPTHACP')) {
            let prime_fact = lot.prime_factures.filter(
              i => i.etat != 'Annulée' && i.belongsTo == true
            );
            prime_fact.forEach(element => {
              this.dataOblige.push(
                NewLModelRowOblige.create({
                  ...element,
                  lot: lot.name,
                  lot_origin: lot
                })
              );
            });
          }
          //surprime facture
          if (this.checkPermission('GAPTHACS')) {
            let surprime_fact = lot.surprime_factures.filter(
              i => i.etat != 'Annulée' && i.belongsTo == true
            );

            surprime_fact.forEach(element => {
              this.dataOblige.push(
                NewLModelRowOblige.create({
                  ...element,
                  lot: lot.name,
                  lot_origin: lot
                })
              );
            });
          }
          if (this.checkPermission('GAPTHACC')) {
            //commision facture
            let commission_fact = lot.commission_factures.filter(
              i => i.etat != 'Annulée' && i.belongsTo == true
            );

            commission_fact.forEach(element => {
              this.dataOblige.push(
                NewLModelRowOblige.create({
                  ...element,
                  lot: lot.name,
                  lot_origin: lot
                })
              );
            });
          }
          if (this.checkPermission('GAPTHACCG')) {
            //commision gestion facture
            let commission_gestion_fact = lot.commission_gestion_factures.filter(
              i => i.etat != 'Annulée' && i.belongsTo == true
            );
            commission_gestion_fact.forEach(element => {
              this.dataOblige.push(
                NewLModelRowOblige.create({
                  ...element,
                  lot: lot.name,
                  lot_origin: lot
                })
              );
            });
          }
          if (this.checkPermission('GAPTHACFCEI')) {
            //commision consolidee_energie_invest

            let consolidee_energie_invest_fact = lot.consolidee_energie_invest_factures.filter(
              i => i.etat != 'Annulée' && i.belongsTo == true
            );
            consolidee_energie_invest_fact.forEach(element => {
              this.dataOblige.push(
                NewLModelRowOblige.create({
                  ...element,
                  lot: lot.name,
                  lot_origin: lot
                })
              );
            });
          }
        });
      });
      this.loaderData = false;
    }
  },
  components: {
    Card: () => import('@/views/component/card.vue'),
    reglement: () => import('@/views/component/reglement/reglement.vue')
  }
};
</script>
<style scoped lang="scss">
.style-somme-th-ttc {
  font-family: 'Montserrat', sans-serif;
  background-color: #d0e4f5;
  position: absolute;
  left: 30px;
  bottom: 7px;
  width: auto;
  height: 35px;
  border-radius: 30px;
  z-index: 120;
}
</style>
<style lang="scss">
.tableFactureObligeeVue {
  max-height: calc(100vh - 180px) !important;
  height: calc(100vh - 180px) !important;
}
</style>
