export default class NewLModelRowOblige {
  constructor(data) {
    this.date_echeance = data.date_echeance;
    this.montant_regle = data.montant_regle;
    this.num = data.num;
    this.reste = data.reste;
    this.total_ttc = data.total_ttc;
    this.vendeur = data.vendeur.nom_compagnie;
    this.vendeur_id = data.vendeur_id;
    this.client = data.acheteur.nom_compagnie;
    this.client_id = data.acheteur_id;
    this.reglements = data.reglements;
    this.date_paiement = data.date_paiement;
    this.total_tva = data.total_tva;
    this.total_ht = data.total_ht;
    this.type = data.type;
    this.etat = data.etat;
    this.check = false;
    this.id = data.id;
    this.lot = data.lot;
    this.payment_type = data.payment_type;
    this.facture_origine = data;
    this.statut_client = data.statut_client;
    this.belongsTo = data.belongsTo;
    this.typeReglement = data.typeReglement;
    this.lot_origin = data.lot_origin;
  }
  static create(data) {
    return new NewLModelRowOblige(data);
  }
  updateDataReglement(data) {
    this.reste = data.reste;
    this.reglements = data.reglements;
    this.date_echeance = data.date_echeance;
    this.montant_regle = data.montant_regle;
    this.date_paiement = data.date_paiement;
    this.payment_type = data.payment_type;
    this.etat = data.etat;
    this.statut_client = data.statut_client;
  }
}
